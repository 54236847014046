import { getAuthMethod, setAuthMethod, isLoginPath, getTokenData, verifyAccessToken, verifySessionIdToken, getIdTokenData, getLastTokenScope } from '../utils';
import { TISP_LOGIN } from '../consts';

// To avoid repeat invocations, we store a reference to the Promise returned
// by any ongoing token management process.
let manageTokensPromise;

const setUpNewSessionToken = async ({
  origin,
  redirectToAuthorize,
  redirectToLogin,
  fetchAndStoreTokenUsingIdToken,
  refreshIDToken,
  authorizeFetchAndStoreToken,
}) => {
  console.debug('ccauth', 'manageTokensLogic', 'session token validation failed');

  // If no last scope and origin (same as login app domain) we are not logged in, in case of SSO we must go thorugh authorize to check if actually are logged in
  const scope = getLastTokenScope();
  if (!scope) {
    return location.origin.startsWith(origin) ? redirectToLogin(false, location.href) : redirectToAuthorize(location.href);
  }

  const authMethod = getAuthMethod();
  if (authMethod !== TISP_LOGIN) {
    // We have id token in session thus we are in AZURE mode
    const fetchToken = () => fetchAndStoreTokenUsingIdToken(getIdTokenData().idToken, scope);

    // If we have a valid IDToken just fetch the TISP token
    if (verifySessionIdToken()) {
      return fetchToken();
    }

    // We need to renew id token and then fetch TISP token
    await refreshIDToken();
    return fetchToken();
  }

  // No valid token, not in SSO and no code try to authorize, will give us a new token if remember me cookie is present
  return authorizeFetchAndStoreToken(scope);
};

const manageTokensLogic = async ({
  code,
  state,
  error,
  origin,
  skipTokenCheck,
  fetchAndStoreTokenUsingIdToken,
  authorizeFetchAndStoreToken,
  fetchAndStoreTokenUsingCode,
  refreshIDToken,
  fetchIDToken,
  redirectToLogin,
  redirectToAuthorize,
  validateRedirectUrl,
  analytics = {},
  errorHandler,
}) => {
  try {
    // Ok got code thus we should be in authorization code flow from our TISP IDP, thus we need to fetch a token using the code
    if (code && !location.pathname.match(/\/(login\/app|login\/mytruck|login\/mytruckna)/g)) {
      const urlParamsClone = new URLSearchParams(location.search);

      // Remove code and state from url, thus no later logic will reuse them
      urlParamsClone.delete('code');
      urlParamsClone.delete('state');

      // in case we are in OICD also remove session_state parameter (we do not use this feature)
      urlParamsClone.delete('session_state');
      urlParamsClone.delete('device_tenant_id');
      const searchString = urlParamsClone.toString();
      history.pushState({}, null, `${location.pathname}${searchString.length ? `?${searchString}` : ''}`);

      if (!state) {
        throw new Error('No state');
      }

      const stateSplit = state.split('_');
      // state should contain the auth method
      setAuthMethod(stateSplit[0]);

      const { sentry, adobe } = analytics;

      if (getAuthMethod() !== TISP_LOGIN) {
        // WE are in OICD flow
        const { idToken } = await fetchIDToken(validateRedirectUrl(location), code);
        if (isLoginPath()) {
          // We are in login app thus it has to be the scope selection step, scope === default
          return await fetchAndStoreTokenUsingIdToken(idToken, 'default');
        }
        const tokenResult = await fetchAndStoreTokenUsingIdToken(idToken, stateSplit[1]);

        if (tokenResult?.accessToken) {
          console.debug('ccauth', 'manageTokensLogic', 'Got valid access token');
          sentry?.metrics?.increment?.('successful_login', 1);
          adobe.sendDeferredLoginSuccessfulEvent();
        }

        // TODO: Capture Adobe login event here
        return tokenResult;
      }
      const tokenResult = await fetchAndStoreTokenUsingCode(validateRedirectUrl(location), code, state);
      console.debug('ccauth', 'manageTokensLogic', 'Got token result', tokenResult);

      if (tokenResult?.accessToken) {
        console.debug('ccauth', 'manageTokensLogic', 'Got valid access token');
        sentry?.metrics?.increment?.('successful_login', 1);
        adobe.sendDeferredLoginSuccessfulEvent();
      }

      return tokenResult;
    }

    // TODO: is this rly needed since default return is the same?
    if (isLoginPath()) {
      console.debug('ccauth', 'manageTokensLogic', 'is running login path');
      return getTokenData();
    }

    /// NOOOOOO ERROR
    if (error) {
      return redirectToLogin(error, `${location.origin}${location.pathname}`);
    }

    // if (skipTokenCheck) {
    //   console.debug('ccauth', 'manageTokensLogic', 'skipping token check');
    // }

    if (skipTokenCheck || !verifyAccessToken()) {
      console.debug('ccauth', 'manageTokensLogic', 'is running no access token path');
      return await setUpNewSessionToken({
        origin,
        redirectToAuthorize,
        redirectToLogin,
        fetchAndStoreTokenUsingIdToken,
        refreshIDToken,
        authorizeFetchAndStoreToken,
      });
    }

    const { scope } = getTokenData();

    if (scope === 'default') {
      console.debug('ccauth', 'manageTokensLogic', 'found default scope in token data, redirecting to login');
      redirectToLogin(false, true);
    }
  } catch (err) {
    return errorHandler(err);
  }
  return getTokenData();
};

const manageTokens = ({
  code,
  state,
  error,
  authStop,
  origin,
  skipTokenCheck,
  noAuth,
  fetchAndStoreTokenUsingIdToken,
  authorizeFetchAndStoreToken,
  fetchAndStoreTokenUsingCode,
  fetchIDToken,
  refreshIDToken,
  redirectToLogin,
  redirectToAuthorize,
  validateRedirectUrl,
  analytics,
}) => {
  console.debug('ccauth', 'manageTokens', 'initiated', 'auth_stop:', authStop);

  // If we are in noAuth mode, skip validation
  if (noAuth) {
    console.debug('ccauth', 'manageTokens', 'noAuth mode, exiting');
    return true;
  }

  // In case authStop we are most likely in a renewal don't continue
  if (authStop) {
    return true;
  }

  const errorHandler = (err) => {
    console.error('ccauth', 'renew token (this may be a "controlled" error)', err.toString());

    if (location.origin.startsWith(origin)) {
      return redirectToLogin(err, location.href);
    }
    return redirectToAuthorize(location.href);
  };

  // Execute the main logic
  manageTokensPromise = manageTokensLogic({
    code,
    state,
    error,
    origin,
    noAuth,
    skipTokenCheck,
    fetchAndStoreTokenUsingIdToken,
    authorizeFetchAndStoreToken,
    fetchAndStoreTokenUsingCode,
    fetchIDToken,
    refreshIDToken,
    redirectToLogin,
    redirectToAuthorize,
    validateRedirectUrl,
    errorHandler,
    analytics,
  });

  return manageTokensPromise.finally((input) => {
    manageTokensPromise = null;
    return input;
  });
};

const makeManageTokens = ({
  origin,
  fetchAndStoreTokenUsingIdToken,
  fetchAndStoreTokenUsingCode,
  fetchIDToken,
  refreshIDToken,
  noAuth,
  legacyAzureAuthorize,
  redirectToLogin,
  redirectToAuthorize,
  validateRedirectUrl,
  authorizeFetchAndStoreToken,
  analytics,
}) => {
  return ({ skipTokenCheck = false } = {}) => {
    console.debug('ccauth', 'manageTokens', 'running manageTokens', skipTokenCheck);
    if (!manageTokensPromise) {
      const urlParams = new URLSearchParams(location.search);
      manageTokensPromise = manageTokens({
        code: urlParams.get('code'),
        state: urlParams.get('state'),
        error: urlParams.get('error'),
        authStop: urlParams.has('auth_stop'),
        noAuth,
        origin,
        skipTokenCheck,
        fetchAndStoreTokenUsingIdToken,
        fetchAndStoreTokenUsingCode,
        fetchIDToken,
        refreshIDToken,
        legacyAzureAuthorize,
        redirectToLogin,
        redirectToAuthorize,
        validateRedirectUrl,
        authorizeFetchAndStoreToken,
        analytics,
      });
    } else {
      // console.debug('ccauth', 'manageTokens', 'call is ongoing return previous promise');
    }
    return manageTokensPromise;
  };
};

export default makeManageTokens;
