// TODO: split up util functions for each respective usage.

export const utilWildcard = (str, wc) => {
  if (str === wc) {
    return true;
  }
  const regExpEscape = (s) => s.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&');
  return new RegExp(`^${wc.split(/\*+/).map(regExpEscape).join('.*')}$`).test(str);
};

export const getStoredRedirectUrl = () => sessionStorage.getItem('ccauth-redirect-uri') || '';
export const setStoredRedirectUrl = (value) => sessionStorage.setItem('ccauth-redirect-uri', decodeURIComponent(value));

export const getLoginHint = () => localStorage.getItem('ccauth-login-hint') || '';
export const setLoginHint = (value) => localStorage.setItem('ccauth-login-hint', decodeURIComponent(value));
export const removeStoredRedirectUrl = () => sessionStorage.removeItem('ccauth-redirect-uri');

export const isExcludedAuthPath = () =>
  [
    '/transporttracking',
    '/charge',
    '/icons',
    '/support/cookiesstatement',
    '/support/systemstatus',
    '/public/request',
    '/global-notification',
    '/report/staticreport',
  ].some((noAuthPath) => window.location.pathname === noAuthPath);

export const isLoginPath = () => ['/login', '/implicit-login'].some((loginPath) => location?.pathname?.startsWith(loginPath));

export const getTokenData = () => JSON.parse(sessionStorage.getItem('sessionToken') || '{}');
export const getIdTokenData = () => JSON.parse(sessionStorage.getItem('sessionIDToken') || '{}');

export const getAuthMethod = () => localStorage.getItem('auth-method');
export const setAuthMethod = (value) => localStorage.setItem('auth-method', value);

export const getLastTokenScope = () => localStorage.getItem('ccauth-last-token-scope');
export const setLastTokenScope = (value) => localStorage.setItem('ccauth-last-token-scope', value);

export const removeAuthMethod = () => localStorage.removeItem('auth-method');

export const getOrigin = () => window.location.origin;

export const getPartnerTokenData = (audience) => JSON.parse(sessionStorage.getItem(`sessionToken-${audience}`) || '{}');

export const generateAndStoreTispState = (scope = 'default') => {
  // Add auth method to state
  let state = 'tisp_';
  if (location.origin.indexOf('localhost') >= 0) {
    state += 'test1234';
  } else {
    const possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 10; ) {
      state += possible.charAt(Math.floor(Math.random() * possible.length));
      i += 1;
    }
  }
  state = `${state}_${scope}`;
  sessionStorage.setItem('ccauth-state', state);
  return state;
};

export const isIFrame = () => window.self !== window.top;

export const isLocalEnv = () => location.hostname === 'localhost' || location.hostname === '127.0.0.1';

export const verifyState = (state) => sessionStorage.getItem('ccauth-state') === state;

export const verifySessionIdToken = (diff = 100) => {
  const { expirationTime } = getIdTokenData();
  return parseInt(expirationTime, 10) * 1000 - Date.now() > diff;
};

export const parseJwt = (token) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split('')
      .map((c) => {
        return `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`;
      })
      .join(''),
  );
  return JSON.parse(jsonPayload);
};

export const verifyAccessToken = (diff = 90000) => {
  // console.debug('ccauth', 'verifyAccessToken', 'Verifying sessiontoken');
  const { expiresIn, tokenRegistered } = getTokenData();
  if (expiresIn && tokenRegistered) {
    const expiresAt = new Date(new Date(tokenRegistered).getTime() + parseInt(expiresIn, 10) * 1000).getTime();
    const now = Date.now();
    // console.debug('ccauth', 'verifyAccessToken', {
    //   expiresAt,
    //   expiresIn,
    //   now,
    //   registered: new Date(tokenRegistered).getTime(),
    //   timeToExpiry: expiresAt - now,
    //   expired: expiresAt - now > diff,
    // });
    return expiresAt - now > diff;
  }
  return false;
};

export const verifyPartnerAccessToken = (audience, diff = 60000) => {
  console.debug('ccauth', 'verifyPartnerAccessToken', 'Verifying sessiontoken');
  const { tokenRegistered: accessTokenRegistered } = getTokenData();
  const { expiresIn, tokenRegistered } = getPartnerTokenData(audience);
  // TODO: not great.
  if (expiresIn && tokenRegistered && accessTokenRegistered) {
    const partnerTokenTimestamp = new Date(tokenRegistered).getTime();
    // In case partner token is older than access token return false
    if (new Date(accessTokenRegistered).getTime() > partnerTokenTimestamp) {
      return false;
    }

    const expiresAt = new Date(partnerTokenTimestamp + parseInt(expiresIn, 10) * 1000).getTime();
    const now = Date.now();
    const result = expiresAt - now > diff;
    console.debug('ccauth', 'verifyAccessToken', 'got verify token result', result);
    return result;
  }
  return false;
};

export const base64urlEncode = (input) => {
  return btoa(input).replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
};

export const generateCodeVerifier = () =>
  base64urlEncode(
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      // eslint-disable-next-line no-bitwise
      const r = (crypto.getRandomValues(new Uint32Array(1))[0] * 2 ** -32 * 16) | 0;
      // eslint-disable-next-line no-bitwise
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    }),
  );

export const generateCodeChallengeAndStoreVerifier = async () => {
  const codeVerifier = generateCodeVerifier();
  sessionStorage.setItem('ccauth-code-verifier', codeVerifier);
  const codeVerifier256 = await crypto.subtle.digest('SHA-256', new TextEncoder().encode(codeVerifier));
  const hashArray = new Uint8Array(codeVerifier256); // convert buffer to byte array
  return String.fromCharCode.apply(null, hashArray); // convert bytes to string
};
